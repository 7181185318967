import Tabulator from 'tabulator-tables';
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["directory_table"]

  initialize() {
    if (this.hasDirectory_tableTarget) {
      let data = this.data.get("tabulator-datarows");
      // let table = this.setTabulatorTable();
      let table = this.directoryTabulatorTable();
      table.setData(data)
    }
  }

  cityCatalogValueForm(event) {
    event.preventDefault();
    let url = this.data.get("form-url");
    this.openLayoutModalMD(url);
  }

  catalogValueForm(event) {
    event.preventDefault();
    let url = this.data.get("form-url");
    this.openLayoutModal(url);
  }

  searchCountry(event) {
    let searchInput = event.currentTarget;
    let searchValue = searchInput.value, i;
    const countriesList = document.getElementById("countries_list");
    const countryItems = countriesList.querySelectorAll("li.country");
    for (i = 0; i < countryItems.length; i++) {
      let _this = countryItems[i];
      let countryName = _this.querySelector("span.name").innerHTML;
      if (countryName.search(new RegExp(searchValue, "i")) < 0) {
        _this.classList.add('hidden');
      } else {
        _this.classList.remove('hidden');
      }
    }
  }

  directoryTabulatorTable() {
    const columns = this.data.get("table-columns");
    let table = new Tabulator("#tabulator-table", {
      columns: JSON.parse(columns),
      height: "100%",
      dataTree: true,
      dataTreeStartExpanded: true,
      layout: "fitColumns",
      resizableRows: true
    });

    return table;
  }
}
