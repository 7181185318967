import { ApplicationController } from "../application_controller";
import Choices from "choices.js";
import BSN from "bootstrap.native";
import { getMetaValue } from "helpers";
import { Draggable } from 'agnostic-draggable';

const TABLE_ID = "deal_route_stages_form_table"

export default class extends ApplicationController {
  static targets = [
    "choices_deal_service_transports_city_search",
    "choices_deal_service_transports_route_stage_search",
    "deal_transports_modal_xl",
    "deal_transports_import_route_stages_modal"
  ]

  initialize() {
    if (this.hasChoices_deal_service_transports_city_searchTarget) { this.routeStagesCitySearch(); return; }
    if (this.hasChoices_deal_service_transports_route_stage_searchTarget) { this.routesStagesServiceSearch(); return; }

    if (this.hasDeal_transports_modal_xlTarget) {
      window.deal_transports_modal_xl = new BSN.Modal(this.deal_transports_modal_xlTarget, {
        backdrop: "static",
        keyboard: true
      });
    }

    if (this.hasDeal_transports_import_route_stages_modalTarget) {
      window.deal_transports_import_route_stages_modal = new BSN.Modal(this.deal_transports_import_route_stages_modalTarget, {
        backdrop: "static",
        keyboard: true
      });
    }
  }

  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    if (window.deal_transports_modal_xl) {
      this.setModalContent(window.deal_transports_modal_xl, url);
      super.draggable_modal('#deal_transports_modal_xl .modal-content');
    }
  }


  importRouteStagesOpen(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");

    if (window.deal_transports_import_route_stages_modal) {
      this.setModalContent(window.deal_transports_import_route_stages_modal, url)
      super.draggable_modal('#deal_transports_import_route_stages_modal .modal-content');
      this.hideTransportModal();
      this.hideRouteStagesModal();
    }
  }

  showModal() {
    if (window.deal_transports_modal_xl) {
      this.hideTransportModal()
      this.hideRouteStagesModal()
      this.removeBackdrops();
      window.deal_transports_modal_xl.toggle();
      window.deal_transports_modal_xl.update();
    }
  }

  hideTransportModal() {
    if (window.deal_transports_modal_xl) {
      window.deal_transports_modal_xl.hide();
      window.deal_transports_modal_xl.update();
    }
  }

  hideRouteStagesModal() {
    if (window.deal_transports_import_route_stages_modal) {
      window.deal_transports_import_route_stages_modal.hide();
      window.deal_transports_import_route_stages_modal.update();
    }
  }


  collapseServices(event) {
    event.stopImmediatePropagation();
    let target = event.currentTarget;
    let collapse = new BSN.Collapse(target)
    if (target.classList.contains("collapsed"))
      collapse.show();
    else collapse.hide();
  }

  toggleRouteStagesTable(event) {
    event.preventDefault();
    let element_url = event.currentTarget.getAttribute('href');
    let table = document.querySelector(element_url);
    event.currentTarget.classList.toggle("collapsed");
    if (table) table.classList.toggle("show");
  }

  onPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;

    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });
      // show child if needed
      const rowId = data.deal_service_id
      // update table data
      // this.updateTable(rowId)
      let transportsBody = document.getElementById("deal_services_transport")
      if (transportsBody) {
        transportsBody.innerHTML = data.table_content
      }
      window.deal_transports_modal_xl.hide();
    }
  }

  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    // modal set content
    window.deal_transports_modal_xl.setContent(data.form_content)
  }


  onImportPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;

    if (data) {
      const table = document.getElementById(TABLE_ID);
      table.tBodies[0].querySelectorAll("tr:not(.hidden)").forEach( el => {
        el.remove()
      })

      for (let i = 0; i < data.routes_stages.length; i++) {
        table.tBodies[0].insertAdjacentHTML("beforeend", data.routes_stages[i]);
      }
      this.reindexFormTableRows();

      this.showModal();
    }
  }

  onImportPostPutError(event) {
    console.log("in onImportPostPutError")
    const [data, status, xhr] = event.detail;
    // modal set content
    window.deal_transports_import_route_stages_modal.setContent(data.form_content)
  }

  async addNewRouteDay(event) {
    event.preventDefault();
    const btn_target = event.currentTarget
    const url = btn_target.getAttribute("data-url")
    if (!btn_target.classList.contains("disabled")) {
      await this.addServiceRequest(url)
    }
  }

  async addNewRouteStage(event) {
    event.preventDefault();
    const btn_target = event.currentTarget
    const url = btn_target.getAttribute("data-url")
    const group_id = btn_target.closest("tr").getAttribute("data-group-id")
    if (!btn_target.classList.contains("disabled")) {
      await this.addServiceRequest(url, group_id)
    }
  }

  async addServiceRequest(url, group_id=null) {
    const data = new FormData();
    if (group_id)
      data.append("group_id", group_id);

    await fetch(url, {
      body: data,
      method: "POST",
      dataType: "text/javascript",
      credentials: "include",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token")
      },
    }).then(function (response) {
      return response.text()
    }).then(text => {
      const table = document.getElementById(TABLE_ID)
      const rows_count = table.tBodies[0].querySelectorAll("tr:not(.hidden)").length + 1;
      let html = text.replace(/NEW_ROW_INDEX/g, rows_count)
      if (group_id)
        Array.from(table.tBodies[0].querySelectorAll("tr[data-group-id='"+group_id+"']"))
             .pop()
             .insertAdjacentHTML("afterend", html);
      else
        table.tBodies[0].insertAdjacentHTML("beforeend", html);
      this.reindexFormTableRows()
    })
  }

  removeField(event) {
    event.preventDefault();

    let response = confirm(event.currentTarget.getAttribute("data-confirm-message"));
    if (response) {
      if (event.currentTarget.closest("tr").getAttribute("data-is-parent") == "true") {
        let group_id = event.currentTarget.closest("tr").getAttribute("data-group-id");
        let table = event.currentTarget.closest("table");
        table.querySelectorAll("tr[data-group-id='"+group_id+"'] td:not(.button)")
              .forEach( el => el.remove() )
        table.querySelectorAll("tr[data-group-id='"+group_id+"']")
              .forEach( el => {
                el.classList.add("hidden");
                let id = el.querySelector("td.button button:last-child").getAttribute("data-id");
                if (id != "nil")
                  document.getElementById(id).value = true
              })

      } else {
        let tr = event.currentTarget.closest("tr")
        let id = event.currentTarget.getAttribute("data-id")
        if (id) {
          if (id != "nil") {
            let destroy_target = document.getElementById(id)
            if (destroy_target) destroy_target.value = true
          }
        }
        tr.querySelectorAll('td:not(.button)').forEach( el => el.remove() );
        tr.classList.add("hidden");
      }
      this.reindexFormTableRows()
    }
  }


  reindexFormTableRows() {
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].querySelectorAll("tr[data-is-parent=true]:not(.hidden)")
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const index_target = tr.querySelector("span.index")
      index_target.innerHTML = i + 1
    }
  }



  async setModalContent(modal, url) {
    // await - https://learn.javascript.ru/async-await#await
    let content_response = await fetch(url);
    let content = await content_response.text();
    //
    // https://thednp.github.io/bootstrap.native/#componentModal
    //

    if (modal) {
      this.removeBackdrops();
      modal.setContent(content);
      modal.show();
      modal.update();
    }

  }

  removeBackdrops() {
    const modalsBackdrops = document.getElementsByClassName('modal-backdrop');

    // remove every modal backdrop
    for (let i = 0; i < modalsBackdrops.length; i++) {
      document.body.removeChild(modalsBackdrops[i]);
    }
  }

  /// choices ///

  routeStagesCitySearch() {
    const target = this.choices_deal_service_transports_city_searchTarget;
    const options = JSON.parse(target.dataset.options || null);
    const selected = JSON.parse(target.dataset.selected || null);
    const reload_field = target.getAttribute("data-reload-field-id");

    let form_target = target.closest('form')
    let this_controller = this.thisController(form_target)

    let choice = new Choices(target, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      searchFields: ["customProperties.cyr", "customProperties.lat"],
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    // set as input attribute
    target.choices = choice;

    if (target.getAttribute("data-reload-field-id")) {
      let reload = this.reloadChoice;
      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          reload(this, this_controller,  event.detail.value);
        },
        false,
      );
    }
    if (options) target.choices.setChoices(options, "value", "label", false)
    if (selected) target.choices.setChoiceByValue(selected)

    this.addValidateTrigger(target, target.choices)
    this.setItemsWidthToMaxWidth(target)
  }

  reloadChoice(target, this_controller, selected_id) {
    let linked_field_id = target.getAttribute("data-reload-field-id");
    let linked_field = document.getElementById(linked_field_id);

    if (linked_field) {
      linked_field.setAttribute("data-city-id", selected_id);

      let linked_choice = linked_field.choices;

      linked_choice.clearInput();
      linked_choice.clearStore();

      this_controller.getAjaxSearchData(linked_field, "", false)

      linked_choice.showDropdown();
    }
  }

  routesStagesServiceSearch() {
    let target = this.choices_deal_service_transports_route_stage_searchTarget;
    let reload_datas = this.getAjaxSearchData

    let choice = new Choices(target, {
      removeItemButton: true,
      removeItems: true,
      searchEnabled: true,
      searchChoices: false,
      placeholder: true,
      duplicateItemsAllowed: false,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    // set as input attribute

    target.choices = choice;
    reload_datas(target, null, true);

    choice.passedElement.element.addEventListener(
      "search",
      function (event) {
        reload_datas(this, event.detail.value, false);
      },
      false,
    );

    this.addValidateTrigger(target, target.choices)
    this.setItemsWidthToMaxWidth(target)
  }

  async getAjaxSearchData(target, q, on_init = false) {

    let url = target.getAttribute("data-url");
    let current_id = target.getAttribute("data-current-id");
    let city_id = target.getAttribute("data-city-id");
    let choice = target.choices;

    if (on_init && current_id != null) {
      url += "&current_id=" + current_id
    } else if (q != null) {
      url += "&q=" + q
    }

    if (city_id != null){
      url += "&city_id=" + city_id
    }

    await fetch(url)
      .then(res => res.json())
      .then((data) => {

        choice.setChoices(data, 'value', 'label', true)

        if (on_init && current_id != null) {
          choice.setChoiceByValue(current_id.split(","));
        }
      });
  }

  addValidateTrigger(html_element, choices_object) {
    let form, controller, fieldContainer;
    form = html_element.closest('form')
    if (form != undefined)
      controller = this.formController(form)
    if (controller != undefined)
      fieldContainer = html_element.closest('.form-group');

    if (controller != undefined && fieldContainer != undefined) {
      choices_object.passedElement.element.addEventListener(
        'change',
        function (event) {
          controller.checkChoicesMulti(fieldContainer);
        },
        false,
      );
    }
  }


  messageText(key) {
    let locale = document.body.getAttribute("data-lang");
    const message_list = {
      "ru": {
        loadingText: "Загрузка...",
        noResultsText: "Ничего не найдено",
        noChoicesText: "Нет вариантов выбора",
        itemSelectText: "Нажмите для выбора"
      },
      "en": {
        loadingText: "Loading...",
        noResultsText: "No results found",
        noChoicesText: "No choices to choose from",
        itemSelectText: "Press to select"
      }
    }
    return message_list[locale][key];
  }

  setItemsWidthToMaxWidth(html_element) {
    const choose_div = html_element.closest(".choices")
    let choices_object = html_element.choices

    choices_object.passedElement.element.addEventListener(
      'showDropdown',
      function (event) {
        const choices_dropdown = choose_div.querySelector(".choices__list--dropdown")
        const items = choices_dropdown.getElementsByClassName('choices__item--selectable')
        if (items.length > 0) {
          const widths = [].slice.call(items).map(function (div) { return div.getBoundingClientRect().width; });
          const maxWidth = Math.max.apply(null, widths);
          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            item.style.width = maxWidth + "px";
          }
        }
      },
      false,
    );
  }

  formController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "layout--form")
  }

  thisController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "deals--transports")
  }

}