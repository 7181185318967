import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["country"]
  //
  // example
  // https://onrails.blog/2018/03/13/stimulus-js-tutorial-how-do-i-update-my-model-from-a-checkbox/
  //
  toggle(event) {
    event.preventDefault();
    // console.log("country toggle");
    this.toggleUpdate(this.data.get("update-url"), "country[is_active]", this.countryTarget.checked, this.countryTarget);
  }

  newCity(event) {
    event.preventDefault();
    let url = this.data.get("new-url");
    this.openLayoutModal(url);
  }

  editCity(event) {
    event.preventDefault();
    let url = this.data.get("edit-url");
    this.openLayoutModal(url);
  }

  searchCountry(event) {
    let searchInput = event.currentTarget;
    let searchValue = searchInput.value, i;
    const countriesList = document.getElementById("countries_list");
    const countryItems = countriesList.querySelectorAll("li.country");
    for (i = 0; i < countryItems.length; i++) {
      let _this = countryItems[i];
      let countryName = _this.querySelector("span.name").innerHTML;
      if (countryName.search(new RegExp(searchValue, "i")) < 0) {
        _this.classList.add('hidden');
      } else {
        _this.classList.remove('hidden');
      }
    }
  }
}
