import InfiniteScroll from "infinite-scroll"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["currency", "rates_table", "date_value"]

  connect() {
    if (this.hasRates_tableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createTable(content_url);
    }
  }

  rateValueForm(event) {
    event.preventDefault();
    let url = this.data.get("form-url");
    this.openLayoutModal(url);
  }

  searchCountry(event) {
    let searchInput = event.currentTarget;
    let searchValue = searchInput.value, i;
    const currenciesList = document.getElementById("all_currencies");
    const currencyItems = currenciesList.querySelectorAll("li.currency");
    for (i = 0; i < currencyItems.length; i++) {
      let _this = currencyItems[i];
      let currencyName = _this.querySelector("span.name").innerHTML;
      if (currencyName.search(new RegExp(searchValue, "i")) < 0) {
        _this.classList.add('hidden');
      } else {
        _this.classList.remove('hidden');
      }
    }
  }

  // Main table filters
  setFilterToTable() {
    const table = this.rates_tableTarget.tabulator;
    let filters = []
    // let date_value = []
    if (this.hasDate_valueTarget) {
      filters.push({
        field: "date_value",
        type: "=",
        value: this.date_valueTarget.value
      })
    }

    if (filters.length) {
      table.setFilter(filters);
    }
  }


  // create table
  async createTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.rates_tableTarget;
    const height = document.getElementById('currencie_rates').clientHeight;
    let table_props = {
      persistenceID: "exchange_rates_table",
      layout: "fitDataFill",
      resizableColumns: false,
      headerSort: false,
      height: (height - 100) + "px",
      // columns
      columns: JSON.parse(columns),
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      // Ajax
      ajaxURL: url,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
    }
    // table props
    const default_props = super.tabulatorDefaultProps
    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }


}