import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["new_currency_modal", "currency_text"]

  connect(){
    if (this.hasNew_currency_modalTarget) {
      if (this.data.get("open-modal") == "1") {
        this.openLayoutModalXL(this.data.get("form-url"));
      }
    }
  }

  accountForm(event) {
    event.preventDefault();
    let url = this.data.get("form-url");
    this.openLayoutModalXL(url);
  }

  updateCurrencyFields(event){
    const currency = event.currentTarget.selectedOptions[0].value;
    this.currency_textTarget.innerHTML = currency;
  }

}
