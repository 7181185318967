import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = [
    "route",
    "route_trains",
    "ul",
    "li",
    "sort_route",
    "train_price_template",
    "form_table_train_prices_table",
    "form_table_train_prices_tbody"
  ]

  changeTrainPriceTrCurrency(event) {
    const tr = event.currentTarget.closest("tr");
    const currency = event.currentTarget.getAttribute("value");
    const trCurrencyText = tr.querySelectorAll(".modal_currency_text");
    trCurrencyText.forEach(el => {
      el.innerHTML = currency;
    });
  }

  removeTrainPriceFileds(event) {
    event.preventDefault();
    let response = confirm(event.currentTarget.getAttribute("data-confirm-message"));
    if (response) {
      const tr = event.currentTarget.closest("tr");
      let id = event.currentTarget.getAttribute("data-id")
      if (id) {
        if (id != "nil") {
          let destroy_target = document.getElementById(id)
          if (destroy_target) destroy_target.value = true
        }
      }
      tr.remove();
      this.reindexFormTableRows()
    }
  }

  addNewPriceRow(event) {
    event.preventDefault();
    const template_row = this.train_price_templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.form_table_train_prices_tbodyTarget.insertAdjacentHTML('beforeend', template_row)
    this.reindexFormTableRows()
  }

  newRoute(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModal(url);
  }

  editRoute(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModal(url);
  }

  openRouteModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalLG(url);
  }

  //
  // reindex table
  //
  reindexFormTableRows() {
    const table = this.form_table_train_prices_tableTarget
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const index_target = tr.querySelector("span.index")
      index_target.innerHTML = i + 1
    }
  }

  async sortRouteTrains(event) {
    event.preventDefault();
    let url = this.data.get("sort-url");
    let dir = this.data.get("sort-dir");
    let content_response = await fetch(url + "?dir=" + dir);
    let content = await content_response.text();

    if (document.getElementById("route_trains")) {
      document.getElementById("route_trains").innerHTML = content;
      if (dir == "desc") dir = "asc"
      else dir = "desc"
      this.data.set("sort-dir", dir)
    }
  }


  async sortRoute(event) {
    event.preventDefault();
    let icon = event.currentTarget.querySelector("i")

    let url = this.data.get("sort-url");
    let dir = this.data.get("sort-dir");
    let route_id = this.data.get("route-id");
    if (route_id == null) route_id = ""
    let content_response = await fetch(url + "?dir=" + dir + "&route_id=" + route_id);
    let content = await content_response.text();

    if (document.getElementById("routes_tab")) {
      document.getElementById("routes_tab").innerHTML = content;
      if (dir == "desc") dir = "asc"
      else dir = "desc"
      this.data.set("sort-dir", dir)

    }
  }

  async showRoute(event) {
    event.preventDefault();
    let link = event.currentTarget;
    let url = event.currentTarget.getAttribute("data-url");
    let id = event.currentTarget.getAttribute("data-id");
    let content_response = await fetch(url);
    let content = await content_response.text();
    if (this.hasRoute_trainsTarget) {
      this.diselectAll();
      this.route_trainsTarget.innerHTML = content;
      let li = link.closest("li");
      li.classList.add("active");
      this.data.set("route-id", id)
    }
  }

  diselectAll() {
    this.liTargets.forEach((el) => {
      el.classList.remove("active");
    });
  }

}
