import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
// import AutoNumeric from 'autonumeric';
// import vNotify from "../vanilla-notify"
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["table",
    "main_form",
    "modal_currency_field",
    "tourist_fields_template",
    "form_table_tbody",
    "form_table",
    "applications_link",
    "mapping_dropdown",
    "search",
    "visit_period",
    "application_period",
    "status",
    "supplier",
    "multiplicity",
    "visa_type"
  ]

  connect() {
    // Main table
    if (this.hasTableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createTable(content_url);
    }
  }

  filterTable() {
    this.setFilterToTable(false);
  }

  // update deal service tr and sub table
  onPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });

      // update row data
      const rowData = data.data
      const mainTable = document.getElementById("visa_applications_table")
      const mainTabulator = mainTable.tabulator
      const childTableId = "visa_applications_table_" + rowData.id;
      const row = mainTabulator.getRow(rowData.id)
      row.update(rowData)

      // show child table
      const rowCell = row.getCell("deal_services__applications");
      this.showApplicationsTable(rowCell.getElement())

      // hide form modal
      if (data.save_and_new === true || data.save_and_new === "true") {
        // modal set content
        window.layout_modal_xl.setContent(data.form_content)
      } else {
        window.layout_modal_xl.hide();
      }

    }
  }

  // update deal service form
  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    // modal set content
    window.layout_modal_xl.setContent(data.form_content)
  }

  toggleApplications(event) {
    this.toggleApplicationsTable(event.currentTarget)
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  toggleApplicationsTable(div_target) {
    let tr = div_target.closest('.tabulator-row')
    let icon = div_target.querySelector('i')
    if (tr) tr.classList.toggle("show-child-table")
    if (icon) {
      if (icon.classList.contains("fa-angle-down")) {
        icon.classList.remove("fa-angle-down")
        icon.classList.add("fa-angle-up")
      } else {
        icon.classList.remove("fa-angle-up")
        icon.classList.add("fa-angle-down")
      }
    }
  }

  showApplicationsTable(div_target) {
    let tr = div_target.closest('.tabulator-row')
    let icon = div_target.querySelector('i')
    if (!tr.classList.contains("show-child-table")) tr.classList.toggle("show-child-table")
    if (icon) {
      if (icon.classList.contains("fa-angle-down")) {
        icon.classList.remove("fa-angle-down")
        icon.classList.add("fa-angle-up")
      } else {
        icon.classList.remove("fa-angle-up")
        icon.classList.add("fa-angle-down")
      }
    }
  }

  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  updateCurrencyFields(event) {
    const currency = event.currentTarget.getAttribute("value");
    this.modal_currency_fieldTargets.forEach(el => {
      el.innerHTML = currency;
    })
  }

  addNew(event) {
    event.preventDefault();
    const template_row = this.tourist_fields_templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.form_table_tbodyTarget.insertAdjacentHTML('beforeend', template_row)
    this.reindexFormTableRows()
  }

  removeFileds(event) {
    event.preventDefault();
    let response = confirm(event.currentTarget.getAttribute("data-confirm-message"));
    if (response) {
      const tr = event.currentTarget.closest("tr");
      let id = event.currentTarget.getAttribute("data-id")
      if (id) {
        if (id != "nil") {
          let destroy_target = document.getElementById(id)
          if (destroy_target) destroy_target.value = true
        }
      }
      const table = this.form_tableTarget
      const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
      if (rows.length > 1) {
        tr.remove();
        this.reindexFormTableRows()
      } else {
        const message = this.main_formTarget.getAttribute("data-remove-message")
        alert(message)
      }
    }
  }

  //
  // private
  //
  //

  //
  // enable_disable buttons
  //
  enableDisableSaveButtons() {
    const save_and_new_btn = document.getElementById("visa_application_save_and_new")
    const save_btn = document.getElementById("visa_application_save")
    const empty_form_info = document.getElementById("foods_empty_form_info")
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    if (rows.length > 0) {
      if (save_and_new_btn) save_and_new_btn.removeAttribute("disabled")
      save_btn.removeAttribute("disabled")
      empty_form_info.classList.add("hidden")
    } else {
      if (save_and_new_btn) save_and_new_btn.setAttribute("disabled", "disabled")
      save_btn.setAttribute("disabled", "disabled")
      empty_form_info.classList.remove("hidden")
    }
  }


  // Main table filters
  setFilterToTable(is_mapping) {
    let table = this.tableTarget.tabulator;
    let filters = []

    // let search_data = []
    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasVisit_periodTarget) {
      filters.push({
        field: "visit_period",
        type: "=",
        value: this.visit_periodTarget.value
      })
    }

    if (this.hasApplication_periodTarget) {
      filters.push({
        field: "application_period",
        type: "=",
        value: this.application_periodTarget.value
      })
    }

    if (this.hasStatusTarget) {
      filters.push({
        field: "status",
        type: "=",
        value: this.statusTarget.choices.getValue(true)
      })
    }

    if (this.hasSupplierTarget) {
      filters.push({
        field: "supplier_ids",
        type: "=",
        value: this.supplierTarget.choices.getValue(true)
      })
    }

    if (this.hasMultiplicityTarget) {
      filters.push({
        field: "multiplicity_ids",
        type: "=",
        value: this.multiplicityTarget.choices.getValue(true)
      })
    }

    if (this.hasVisa_typeTarget) {
      filters.push({
        field: "visa_type_ids",
        type: "=",
        value: this.visa_typeTarget.choices.getValue(true)
      })
    }

    if (filters.length) {
      table.setFilter(filters);
    }
  }


  //
  // reindex table
  //
  reindexFormTableRows() {
    const table = this.form_tableTarget
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const index_target = tr.querySelector("span.index")
      index_target.innerHTML = i + 1
    }
  }

  // Create main table
  async createTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    let addVisaApplications = this.addVisaApplicationsSubTable
    let applications_columns = this.applicationsColumns()
    applications_columns = applications_columns[super.appLocale]

    let table_props = {
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 180) + "px",
      persistenceID: "visa_applications_main_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),

      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],

      // pagination
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      //
      // groupBy: "deals__number",
      // groupToggleElement: "header",
      //
      rowFormatter: function (row) {
        const data = row.getData();
        if (data.deal_services__status) {
          row.getElement().classList.add("row-" + data.deal_services__status)
        }
        //
        // add applications sub table
        //
        addVisaApplications(row, applications_columns);
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    // this.addContactsShowColumn(table_target.tabulator);
    table_target.tabulator.setLocale(super.appLocale);
  }

  addVisaApplicationsSubTable(row, applications_columns) {
    const applications_data = row.getData().deal_services__visa_applications
    const tableId = "visa_applications_table_" + row.getData().id;
    const childTable = document.getElementById(tableId)

    if (childTable) {
      const childTabulator = childTable.tabulator
      childTabulator.setData(applications_data)
    } else {
      //create and style holder elements
      let holderEl = document.createElement("div");
      holderEl.classList = "child-table visa-applications-table ml-auto mr-20";
      holderEl.style.padding = "0 0 10px 20px";
      let tableEl = document.createElement("div");
      tableEl.classList = "table table-striped";
      tableEl.setAttribute("id", tableId);
      holderEl.appendChild(tableEl);

      row.getElement().appendChild(holderEl);

      if (applications_data) {
        tableEl.tabulator = new Tabulator(tableEl, {
          minHeight: 300,
          layout: "fitDataTable",
          data: applications_data,
          headerSort: true,
          columns: applications_columns
        })
      }
    }
  }

  applicationsColumns() {
    const columns = {
      "ru": [{
        title: "№",
        field: "index",
        formatter: "plaintext",
        headerSort: false,
        width: 50
      },
      {
        title: "",
        field: "status_html",
        formatter: "html",
        headerSort: false,
        width: 50
      },
      {
        title: "Номер заявки",
        field: "number",
        headerSort: true,
        width: 150
      },
      {
        title: "Дата подачи",
        field: "date",
        headerSort: true,
        width: 150
      },
      {
        title: "Номер телекса",
        field: "telex_number",
        headerSort: true,
        width: 150
      },
      {
        title: "Кол-во",
        field: "count",
        headerSort: true,
        width: 120
      },
      {
        title: "Цена",
        field: "price",
        formatter: "html",
        headerSort: true,
        width: 150
      },
      {
        title: "Итого",
        field: "total",
        formatter: "html",
        headerSort: true,
        width: 150
      },
      {
        title: "Тип оплаты",
        field: "account",
        formatter: "html",
        headerSort: true,
        width: 150
      },
      {
        title: "",
        field: "action_links",
        formatter: "html",
        headerSort: false,
        hozAlign: "right",
        width: 120
      }],
      "en": [{
        title: "№",
        field: "index",
        formatter: "plaintext",
        headerSort: false,
        width: 50
      },
      {
        title: "",
        field: "status",
        formatter: "html",
        headerSort: false,
        width: 50
      },
      {
        title: "Номер заявки",
        field: "number",
        headerSort: true,
        width: 100
      },
      {
        title: "Дата подачи",
        field: "date",
        headerSort: true,
        width: 150
      },
      {
        title: "Номер телекса",
        field: "telex_number",
        headerSort: true,
        width: 150
      },
      {
        title: "Кол-во",
        field: "count",
        headerSort: true,
        width: 100
      },
      {
        title: "Цена",
        field: "price",
        formatter: "html",
        headerSort: true,
        width: 120
      },
      {
        title: "Итого",
        field: "total",
        formatter: "html",
        headerSort: true,
        width: 120
      },
      {
        title: "Тип оплаты",
        field: "account",
        formatter: "html",
        headerSort: true,
        width: 150
      },
      {
        title: "",
        field: "action_links",
        formatter: "html",
        headerSort: false,
        width: 120
      }]
    }
    return columns
  }


}