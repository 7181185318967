import Tabulator from "tabulator-tables";
import AutoNumeric from 'autonumeric';
import vNotify from "../vanilla-notify"
import { getMetaValue } from "helpers";
import { ApplicationController } from "../application_controller";

Tabulator.prototype.extendModule("columnCalcs", "calculations", {
  "visaTotalsText": function (values, data, calcParams) {
    const table = document.getElementById("deal_visa_applications_table")
    if (table)
      return table.getAttribute("data-total-text")
  },

  "visaTotalsValues": function (values, data, calcParams) {
    //values - array of column values
    //data - all table data
    //calcParams - params passed from the column definition object
    const table = document.getElementById("deal_visa_applications_table")
    if (!table) return 0;
    let total_in_main = 0;
    let total_in_additional = 0;
    let main_currency = table.getAttribute("data-main-currency")
    let additional_currency = table.getAttribute("data-additional-currency")

    data.forEach(function (table_data) {
      total_in_main += table_data.total_in_main
      total_in_additional += table_data.total_in_additional
    });

    total_in_main = AutoNumeric.format(total_in_main, {
      allowDecimalPadding: "floats",
      decimalPlaces: 2,
      digitGroupSeparator: " ",
      decimalCharacter: ".",
      decimalCharacterAlternative: ",",
      currencySymbol: main_currency,
      currencySymbolPlacement: "p"
    });

    total_in_additional = AutoNumeric.format(total_in_additional, {
      allowDecimalPadding: "floats",
      decimalPlaces: 2,
      digitGroupSeparator: " ",
      decimalCharacter: ".",
      decimalCharacterAlternative: ",",
      currencySymbol: additional_currency,
      currencySymbolPlacement: "s"
    });

    let total_html = "<div class='font-bold font-info'>" + total_in_main + "</div>"
    total_html += "<span class='text-muted font-size-12 font-weight-normal'>" + total_in_additional + "</span>"
    return total_html;
  }
});


export default class extends ApplicationController {
  static targets = ["table",
    "main_form",
    "modal_currency_field",
    "tourist_fields_template",
    "form_table_tbody",
    "form_table",
    "applications_link"
  ]

  connect() {
    // Main table
    if (this.hasTableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createTable(content_url);
    }
  }

  // update deal service tr and sub table
  onPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });


      // show child if needed
      const rowId = data.deal_service_id

      // update table data
      this.updateTable(rowId)

      if (rowId) {
        // hide applications modal
        if (data.save_and_new === true || data.save_and_new === "true") {
          // modal set content
          window.layout_modal_xl.setContent(data.form_content)
        } else {
          window.layout_modal_xl.hide();
        }
      } else {
        // hide form modal
        window.layout_modal_md.hide();
      }
    }
  }

  // update deal service form
  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    // modal set content
    window.layout_modal_xl.setContent(data.form_content)
  }

  toggleApplications(event) {
    this.toggleApplicationsTable(event.currentTarget)
  }

  toggleApplicationsTable(div_target) {
    let tr = div_target.closest('.tabulator-row')
    let icon = div_target.querySelector('i')
    if (tr) tr.classList.toggle("show-child-table")
    if (icon) {
      if (icon.classList.contains("fa-angle-down")) {
        icon.classList.remove("fa-angle-down")
        icon.classList.add("fa-angle-up")
      } else {
        icon.classList.remove("fa-angle-up")
        icon.classList.add("fa-angle-down")
      }
    }
  }

  showApplicationsTable(div_target) {
    let tr = div_target.closest('.tabulator-row')
    let icon = div_target.querySelector('i')
    if (!tr.classList.contains("show-child-table")) tr.classList.toggle("show-child-table")
    if (icon) {
      if (icon.classList.contains("fa-angle-down")) {
        icon.classList.remove("fa-angle-down")
        icon.classList.add("fa-angle-up")
      } else {
        icon.classList.remove("fa-angle-up")
        icon.classList.add("fa-angle-down")
      }
    }
  }

  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalMD(url);
  }

  updateCurrencyFields(event) {
    const currency = event.currentTarget.getAttribute("value");
    this.modal_currency_fieldTargets.forEach(el => {
      el.innerHTML = currency;
    })
  }

  addNew(event) {
    event.preventDefault();
    const template_row = this.tourist_fields_templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.form_table_tbodyTarget.insertAdjacentHTML('beforeend', template_row)
    this.reindexFormTableRows()
  }

  removeFileds(event) {
    event.preventDefault();
    const tr = event.currentTarget.closest("tr");
    let id = event.currentTarget.getAttribute("data-id")
    if (id) {
      if (id != "nil") {
        let destroy_target = document.getElementById(id)
        if (destroy_target) destroy_target.value = true
      }
    }
    tr.remove();
    this.reindexFormTableRows()
  }

  //
  // private
  //

  //
  // update deal visas tabulator
  //
  async updateTable(showRowId) {
    const enable_disable = document.getElementById("visa_enable_disable")
    if (enable_disable) {
      const content_target = enable_disable.closest(".services_load")
      let controller = this.application.getControllerForElementAndIdentifier(content_target, "layout--load")
      controller.reload(content_target);
    } else {
      // update table data
      const mainTable = document.getElementById("deal_visa_applications_table")
      const mainTabulator = mainTable.tabulator
      await mainTabulator.replaceData()

      if (showRowId) {
        const row = mainTabulator.getRow(showRowId)
        // show child table
        const rowCell = row.getCell("deal_services__applications");
        this.showApplicationsTable(rowCell.getElement())
      }
    }
  }


  //
  // reindex table
  //
  reindexFormTableRows() {
    const table = this.form_tableTarget
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const index_target = tr.querySelector("span.index")
      index_target.innerHTML = i + 1
    }
  }



  // Create main table
  async createTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.tableTarget;

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    let addVisaApplications = this.addVisaApplicationsSubTable
    let applications_columns = this.applicationsColumns()
    applications_columns = applications_columns[super.appLocale]

    let table_props = {
      layout: "fitDataFill",
      virtualDomHoz: false,
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 280) + "px",
      // minHeight: (height - 280) + "px",
      persistenceID: "deals_visa_applications_main_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      // headerSort: false,
      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],

      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      // ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      //
      rowFormatter: function (row) {
        const data = row.getData();
        if (data.deal_services__status) {
          // row.getElement().classList.add("row-" + data.deal_services__status)
          row.getElement().classList.add("row--" + data.deal_services__status + "-bg")
        }
        //
        // add applications sub table
        //
        addVisaApplications(row, applications_columns);
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    // this.addContactsShowColumn(table_target.tabulator);
    table_target.tabulator.setLocale(super.appLocale);
  }

  addVisaApplicationsSubTable(row, applications_columns) {
    const applications_data = row.getData().deal_services__visa_applications
    const tableId = "deal_visa_visa_applications_table_" + row.getData().id;
    const childTable = document.getElementById(tableId)

    if (childTable) {
      const childTabulator = childTable.tabulator
      childTabulator.setData(applications_data)
    } else {
      //create and style holder elements
      let holderEl = document.createElement("div");
      holderEl.classList = "child-table visa-applications-table ml-auto mr-20";
      holderEl.style.padding = "0 0 10px 20px";
      let tableEl = document.createElement("div");
      tableEl.setAttribute("id", tableId);
      tableEl.classList = "table table-striped deal_visas_applications_tabulator";
      holderEl.appendChild(tableEl);

      row.getElement().appendChild(holderEl);

      if (applications_data) {
        tableEl.tabulator = new Tabulator(tableEl, {
          minHeight: 300,
          layout: "fitDataTable",
          data: applications_data,
          headerSort: true,
          columns: applications_columns
        })
      }
    }
  }

  applicationsColumns() {
    const columns = {
      "ru": [{
        title: "№",
        field: "index",
        formatter: "plaintext",
        headerSort: false,
        width: 50
      },
      {
        title: "",
        field: "status_html",
        formatter: "html",
        headerSort: false,
        width: 50
      },
      {
        title: "Номер заявки",
        field: "number",
        headerSort: true,
        width: 150
      },
      {
        title: "Дата подачи",
        field: "date",
        formatter: "plaintext",
        headerSort: true,
        width: 150
      },
      {
        title: "Номер телекса",
        field: "telex_number",
        headerSort: true,
        width: 150
      },
      {
        title: "Кол-во",
        field: "count",
        headerSort: true,
        width: 120
      },
      {
        title: "Цена",
        field: "price",
        formatter: "html",
        headerSort: true,
        width: 150
      },
      {
        title: "Итого",
        field: "total",
        formatter: "html",
        headerSort: true,
        width: 150
      },
      {
        title: "Тип оплаты",
        field: "account",
        formatter: "html",
        headerSort: true,
        width: 150
      },
      {
        title: "",
        field: "action_links",
        formatter: "html",
        headerSort: false,
        hozAlign: "right",
        width: 120
      }],
      "en": [{
        title: "№",
        field: "index",
        formatter: "plaintext",
        headerSort: false,
        width: 50
      },
      {
        title: "",
        field: "status",
        formatter: "html",
        headerSort: false,
        width: 50
      },
      {
        title: "Номер заявки",
        field: "number",
        headerSort: true,
        width: 100
      },
      {
        title: "Дата подачи",
        field: "plaintext",
        headerSort: true,
        width: 150
      },
      {
        title: "Номер телекса",
        field: "telex_number",
        headerSort: true,
        width: 150
      },
      {
        title: "Кол-во",
        field: "count",
        headerSort: true,
        width: 100
      },
      {
        title: "Цена",
        field: "price",
        formatter: "html",
        headerSort: true,
        width: 120
      },
      {
        title: "Итого",
        field: "total",
        formatter: "html",
        headerSort: true,
        width: 120
      },
      {
        title: "Тип оплаты",
        field: "account",
        formatter: "html",
        headerSort: true,
        width: 150
      },
      {
        title: "",
        field: "action_links",
        formatter: "html",
        headerSort: false,
        width: 120
      }]
    }
    return columns
  }
}